import { Injectable } from '@angular/core';
import packageInfo from '../../../../package.json';
import { ApplicationConfiguration } from '../types/application-configuration.type';
import { ApiService } from './api/api.service';
import { LoggingService } from './logging.service';
import { PopupService } from './popup.service';

@Injectable({ providedIn: 'root' })
export class AppConfigurationService {
  get applicationInsightsConnectionString(): string {
    return this.appConfiguration.applicationInsightsConnectionString;
  }

  get isOnPremise(): boolean {
    return this.appConfiguration.isOnPremise;
  }

  get azureApplicationId(): string {
    return this.appConfiguration.azureApplicationId;
  }

  get azureAuthorityTenant(): string {
    return this.appConfiguration.azureAuthorityTenant;
  }

  get clientVersion(): string {
    return this.versions?.client;
  }

  get serverVersion(): string {
    return this.versions?.server;
  }

  get apiVersion(): string {
    return this.versions?.api;
  }

  get licenseExpiration(): Date {
    return this.appConfiguration.licenseExpiration;
  }

  get licenseExpirationWarningDays(): number {
    return this.appConfiguration.licenseExpirationWarningDays;
  }

  private appConfiguration: ApplicationConfiguration;
  private versions: {
    client: string;
    server: string;
    api: string;
  };

  static initialize(apiService: ApiService, appConfigurationService: AppConfigurationService, loggingService: LoggingService, popupService: PopupService): () => Promise<any> {
    return async () => {
      try {
        const versions = await apiService.getVersion();
        appConfigurationService.versions = {
          client: packageInfo.version,
          server: versions.serverVersion.split('+')[0], // remove build metadata
          api: versions.apiVersion,
        };
        appConfigurationService.appConfiguration = await apiService.getApplicationConfiguration();
      } catch (error) {
        await popupService.showInfo('Failed to load application configuration.', 'Server not responding', 'Try again');
        window.location.reload();
        return;
      }

      const appInsightsConnectionString = appConfigurationService.appConfiguration?.applicationInsightsConnectionString;
      loggingService.setupApplicationInsights(appInsightsConnectionString, appConfigurationService.serverVersion);
    };
  }
}
