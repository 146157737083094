@if (filterDefinition$ | async; as filterDefinition) {
  <div class="flex flex-row flex-gap-16-8">
    @for (filter of filterDefinition.filters; track filter.name) {
      @if (filter.type === FilterItemType.Select || filter.type === FilterItemType.MultiSelect) {
        <div class="select-content-width">
          <shared-select-with-search
            [multiple]="filter.type === FilterItemType.MultiSelect"
            [items]="filterSelectionMap.get(filter.name).items"
            [fixedItems]="filterSelectionMap.get(filter.name).fixedItems"
            [selection]="filterSelectionMap.get(filter.name).selection"
            [placeholder]="filter.placeholder"
            [disabled]="disabled"
            (selectionChange)="onFilterValueChanged($event, filter)"
            (closed)="onFilterClosed($event, filter)"
            selectClass="pointee-mat-select"
          />
        </div>
        @if (filter.getTooltip) {
          <ng-container *ngrxLet="filter.getTooltip() as tooltipMsg">
            @if (tooltipMsg) {
              <span class="filter-info">
                <shared-info-icon [tooltip]="tooltipMsg"></shared-info-icon>
              </span>
            }
          </ng-container>
        }
      }
    }
  </div>
}
