import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { SchedulerActionParameter, SchedulerActionParameterValue } from '../../types/scheduler/scheduler-task.type';
import { FormHelper } from '../../helpers/form.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SchedulerParametersHelper } from '../../helpers/scheduler-parameters.helper';
import { DateHelper, DateTimeFormat, DateTimeUnit, DurationFormat, TimeFormat } from '../../helpers/date.helper';
import { SessionParameterType } from '../../enums/session-parameter-type.enum';
import { OrganizationService } from '../../services/organization.service';
import { MapHelper } from '../../helpers/map.helper';
import { TextHelper } from '../../helpers/text.helper';

@Component({
  selector: 'shared-parameters-editor',
  templateUrl: './parameters-editor.component.html',
  styleUrls: ['./parameters-editor.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ParametersEditorComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: ParametersEditorComponent },
  ],
})
export class ParametersEditorComponent implements ControlValueAccessor, OnChanges, Validator {
  readonly SessionParameterType = SessionParameterType;
  @Input() parametersDefinition: SchedulerActionParameter[];

  value: SchedulerActionParameterValue[];
  form: FormArray<FormGroup<ParameterFormControls>> = this.formBuilder.array([] as FormGroup<ParameterFormControls>[]);
  isDisabled: boolean;
  private onChange = (value: SchedulerActionParameterValue[]): void => {};
  private onTouched: () => void = () => {};
  private touched = false;
  private onValidationChange: () => void = () => {};
  private isInitialized = false;
  private types = new Map<string, SessionParameterType | string>();
  private descriptions = new Map<string, string>();
  minCalendarDate = DateHelper.addTime(DateHelper.endOfYear(new Date()), -10, DateTimeUnit.Year);
  maxCalendarDate = DateHelper.addTime(DateHelper.endOfYear(new Date()), 10, DateTimeUnit.Year);

  get f(): FormGroup<ParameterFormControls>[] {
    return this.form.controls;
  }

  get formGroup(): any {
    return this.form;
  }

  get isBluePrism(): boolean {
    return this.organizationService.isBluePrism;
  }

  constructor(
    private formBuilder: FormBuilder,
    private organizationService: OrganizationService
  ) {
    this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => this.notifyChanged());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parametersDefinition != null) {
      this.initializeValues();
    }
  }

  getTypeValidationErrorMessage(type: string): string {
    return `Invalid value for type ${type}`;
  }

  getType(name: string): SessionParameterType | string {
    return this.types.get(name);
  }

  getDescription(name: string): string {
    return this.descriptions.get(name);
  }

  private initializeValues(): void {
    if (this.value == null || this.parametersDefinition == null) {
      return;
    }

    this.form.clear();
    this.parametersDefinition.forEach(parameter => {
      const value = this.value.find(v => v.name === parameter.name)?.value ?? null;
      this.form.push(this.createParameterFormGroup(parameter, value));
    });

    this.types = MapHelper.toDictionary2(
      this.parametersDefinition,
      p => p.name,
      p => p.type
    );
    this.descriptions = MapHelper.toDictionary2(
      this.parametersDefinition,
      p => p.name,
      p => p.description
    );
    this.isInitialized = true;
  }

  private createParameterFormGroup(parameter: SchedulerActionParameter, value?: string): FormGroup<ParameterFormControls> {
    return this.formBuilder.group({
      name: [parameter.name],
      value: [this.convertFromBP(value, parameter.type)],
    }) as FormGroup<ParameterFormControls>;
  }

  private convertFromBP(value: string, type: SessionParameterType | string): string | Date | number {
    if (value == null) {
      return null;
    }

    if (!this.isBluePrism) {
      return value;
    }

    switch (type) {
      case SessionParameterType.Date:
        return DateHelper.parseDate(value, DateTimeFormat.BluePrismDate);
      case SessionParameterType.DateTime:
        return DateHelper.parseDate(value, DateTimeFormat.BluePrismDateTime);
      case SessionParameterType.Time:
        return DateHelper.parseTime(value, TimeFormat.BluePrismTime);
      case SessionParameterType.TimeSpan:
        return DateHelper.parseDuration(value, DurationFormat.BluePrismTimeSpan);
      case SessionParameterType.Number:
        return parseFloat(value);
      default:
        return value;
    }
  }

  private convertToBP(value: string | Date | number, type: SessionParameterType | string): string {
    if (value == null || value === '') {
      return null;
    }

    if (!this.isBluePrism) {
      return value as string;
    }

    switch (type) {
      case SessionParameterType.Date:
        return TextHelper.convertNbspToSpaces(DateHelper.formatDate(value as Date, DateTimeFormat.BluePrismDate));
      case SessionParameterType.DateTime:
        return TextHelper.convertNbspToSpaces(DateHelper.formatDate(value as Date, DateTimeFormat.BluePrismDateTime));
      case SessionParameterType.Time:
        return TextHelper.convertNbspToSpaces(DateHelper.formatTime(value as number, TimeFormat.BluePrismTime));
      case SessionParameterType.TimeSpan:
        return TextHelper.convertNbspToSpaces(DateHelper.formatDuration(value as number | null, DurationFormat.BluePrismTimeSpan));
      case SessionParameterType.Number:
        return (value as number).toString();
      default:
        return value as string;
    }
  }

  private notifyChanged(): void {
    if (!this.isInitialized) {
      return;
    }
    this.onChange(this.form.value.map(v => ({ name: v.name, value: this.convertToBP(v.value, this.getType(v.name)) })));
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // ControlValueAccessor implementation
  writeValue(value: SchedulerActionParameterValue[]): void {
    this.value = value;
    this.initializeValues();
  }

  registerOnChange(onChange: (value: SchedulerActionParameterValue[]) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    FormHelper.setEnabled(this.form, !isDisabled);
  }

  registerOnValidatorChange?(onValidatorChange: () => void): void {
    this.onValidationChange = onValidatorChange;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }
}

interface ParameterFormControls {
  name: FormControl<string>;
  value: FormControl<string | Date | number | null>;
}
