import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'shared-time-span',
  templateUrl: './time-span.component.html',
  styleUrls: ['./time-span.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TimeSpanComponent }],
})
export class TimeSpanComponent implements ControlValueAccessor, OnInit {
  timeSpanForm: FormGroup = this.formBuilder.group<TimeSpanFormControls>({
    days: new FormControl<number | null>(null, { validators: [Validators.min(0), Validators.max(99)] }),
    hours: new FormControl<number | null>(null, { validators: [Validators.min(0), Validators.max(59)] }),
    minutes: new FormControl<number | null>(null, { validators: [Validators.min(0), Validators.max(59)] }),
    seconds: new FormControl<number | null>(null, { validators: [Validators.min(0), Validators.max(59)] }),
  });

  timeSpanValue: any;
  isDisabled = false;
  private onChange: (value: number) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private formBuilder: FormBuilder) {}
  ngOnInit(): void {
    this.timeSpanForm.valueChanges.subscribe(value => {
      let returnValue = null;
      if (value != null) {
        const timeSpanValue = value as { days: number; hours: number; minutes: number; seconds: number };
        if (timeSpanValue.days != null || timeSpanValue.hours != null || timeSpanValue.minutes != null || timeSpanValue.seconds != null) {
          returnValue = (timeSpanValue.days ?? 0) * 86400 + (timeSpanValue.hours ?? 0) * 3600 + (timeSpanValue.minutes ?? 0) * 60 + (timeSpanValue.seconds ?? 0);
          returnValue = returnValue * 1000; // to milliseconds
        }
      }
      this.onChange(returnValue);
      this.onTouched();
    });
  }

  writeValue(value: number): void {
    if (value != null) {
      // Convert milliseconds to seconds
      value = value / 1000;
      const { days, hours, minutes, seconds } = {
        days: value >= 86400 ? Math.floor(value / 86400) : null,
        hours: value >= 3600 ? Math.floor((value % 86400) / 3600) : null,
        minutes: value >= 60 ? Math.floor((value % 3600) / 60) : null,
        seconds: value >= 0 ? value % 60 : null,
      };
      this.timeSpanForm.setValue({ days, hours, minutes, seconds }, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.timeSpanForm.disable();
    } else {
      this.timeSpanForm.enable();
    }
  }
}

interface TimeSpanFormControls {
  days: FormControl<number | null>;
  hours: FormControl<number | null>;
  minutes: FormControl<number | null>;
  seconds: FormControl<number | null>;
}
