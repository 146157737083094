import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { AuthService } from './auth.service';
import { EventBusService, EventType } from './event-bus.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MonitoringStatistics } from '../types/monitoring-statistics.type';
import { SignalRService } from './signalr.service';

@Injectable({ providedIn: 'root' })
export class MonitoringStatisticsService {
  private statisticsSubject = new BehaviorSubject<MonitoringStatistics>(null);

  get statistics$(): Observable<MonitoringStatistics> {
    return this.statisticsSubject.asObservable();
  }

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    eventBusService: EventBusService,
    signalRService: SignalRService,
  ) {
    signalRService.monitoringDataUpdated$.subscribe(() => void this.refresh());
    eventBusService.on(EventType.OrganizationChanged)
      .subscribe(() => {
        this.statisticsSubject.next(null);
        void this.refresh();
      });

    void this.refresh();
  }

  async refresh(): Promise<void> {
    if (!this.authService.isLoggedIn) {
      return;
    }

    try {
      const statistics = await this.apiService.monitoring.getStatistics();
      this.statisticsSubject.next(statistics);
    } catch {
      // ignored
    }
  }
}
