import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import SweetAlert from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class PopupService {
  constructor(private matSnackBar: MatSnackBar) {}

  private close(): void {
    SweetAlert.close();
  }

  async showInfo(message: string, title?: string, okButtonText?: string, standardClose = true, customClass?: string): Promise<void> {
    await SweetAlert.fire({
      title,
      html: message,
      showConfirmButton: true,
      confirmButtonText: okButtonText || 'OK',
      showCloseButton: false,
      closeButtonHtml: this.getCloseButtonHtml(),
      allowOutsideClick: standardClose,
      allowEscapeKey: standardClose,
      customClass: {
        popup: customClass,
        confirmButton: 'swal2-confirm link',
      },
    });
  }

  async showCannotDelete(deleteMessage: string, title?: string, confirmBtnText?: string): Promise<void> {
    await SweetAlert.fire({
      title,
      html: deleteMessage,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: this.getCloseButtonHtml(),
      allowOutsideClick: true,
      allowEscapeKey: true,
      footer: `<button type="button" class="btn btn-link" id="deleteBtnConfirm">${confirmBtnText}</button>`,
      customClass: {
        popup: 'wide',
        footer: 'swal2-footer',
        title: 'swal2-title text-left',
        htmlContainer: 'swal2-html-container text-left',
      },
      willOpen: () => {
        document.getElementById('deleteBtnConfirm').addEventListener('click', () => this.close());
      },
      willClose: () => {
        document.getElementById('deleteBtnConfirm').removeEventListener('click', () => this.close());
      },
    });
  }

  async showOkCancelQuestion(title: string, message: string, okButtonText?: string, cancelButtonText?: string, isPositive = false): Promise<boolean> {
    const sweetAlert = SweetAlert.mixin({
      title,
      html: message,
      showConfirmButton: true,
      confirmButtonText: okButtonText ?? 'OK',
      showCancelButton: true,
      cancelButtonText: cancelButtonText ?? 'Cancel',
      showCloseButton: true,
      closeButtonHtml: this.getCloseButtonHtml(),
      reverseButtons: true,
      customClass: {
        confirmButton: `swal2-confirm ${isPositive ? 'primary' : 'danger'}`,
      },
    });
    return (await sweetAlert.fire()).isConfirmed;
  }

  async showDeleteQuestion(title: string, message: string, deleteButtonText?: string, cancelButtonText?: string): Promise<boolean> {
    const sweetAlert = SweetAlert.mixin({
      title,
      html: message,
      showConfirmButton: true,
      confirmButtonText: deleteButtonText ?? 'Delete',
      showCancelButton: true,
      cancelButtonText: cancelButtonText ?? 'Cancel',
      showCloseButton: true,
      closeButtonHtml: this.getCloseButtonHtml(),
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal2-confirm danger',
      },
    });
    return (await sweetAlert.fire()).isConfirmed;
  }

  async showCustomAlertWithRadioButtons(title: string, options: any, selected: string, okButtonText?: string, cancelButtonText?: string, isPositive = false): Promise<string> {
    const result = await SweetAlert.fire<string>({
      title,
      input: 'radio',
      inputOptions: options,
      inputValue: selected,
      showConfirmButton: true,
      confirmButtonText: okButtonText ?? 'OK',
      showCancelButton: true,
      cancelButtonText: cancelButtonText ?? 'Cancel',
      showCloseButton: true,
      closeButtonHtml: this.getCloseButtonHtml(),
      reverseButtons: true,
      customClass: {
        confirmButton: `swal2-confirm ${isPositive ? 'primary' : 'danger'}`,
      },
    });

    return result.isConfirmed ? result.value : null;
  }

  async showQuestion(title: string, inputValue: string, confirmLabel: string): Promise<string> {
    const sweetAlert = SweetAlert.mixin({
      customClass: {
        confirmButton: 'swal2-confirm primary',
      },
      didOpen: () => {
        sweetAlert.getInput()?.addEventListener('keyup', e => {
          const { value } = e.target as HTMLInputElement;
          if ((value || '').trim().length === 0) {
            sweetAlert.disableButtons();
          } else {
            sweetAlert.enableButtons();
          }
        });
      },
    });

    const result = await sweetAlert.fire<string>({
      html: `<label for="swal-input">${title}:</label>`,
      input: 'text',
      inputValue,
      inputAttributes: {
        id: 'swal-input',
        autocapitalize: 'off',
        class: 'pointee-input',
      },
      inputValidator: r => (!r || r.trim().length < 1) && 'enter valid input',
      confirmButtonText: confirmLabel,
      showCloseButton: true,
      closeButtonHtml: this.getCloseButtonHtml(),
      allowOutsideClick: true,
      backdrop: true,
      showClass: {
        popup: 'animate__animated animate__fadeIn',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOut',
      },
    });

    return result.value;
  }

  showSnackBar(message: string, duration?: number): void {
    this.matSnackBar.open(message, null, {
      duration: duration || 10000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      panelClass: 'snackbar',
    });
  }

  private getCloseButtonHtml(): string {
    return '<div style="width:16px;height:16px;mask:url(assets/svg/shared/close.svg) no-repeat center/16px;background-color:#6b7280;"></div>';
  }
}
