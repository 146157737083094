import { EnvironmentInjector, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalCloseType } from '../components/modal/modal-close-type.enum';
import { BaseModalDialogDirective } from '../directives/base-modal-dialog.directive';
import { ModalSize } from '../enums/modal-size.enum';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private openModals: NgbModalRef[] = [];

  constructor(private ngbModal: NgbModal) {
    this.ngbModal.activeInstances.subscribe(modals => this.openModals = modals);
  }

  open<TModalDialog extends BaseModalDialogDirective>(type: new (...args: never[]) => TModalDialog, setParameters?: (d: TModalDialog) => void, injector?: EnvironmentInjector): NgbModalRef {
    const modalRef = this.ngbModal.open(type, this.getModalOptions(type, injector));
    const component = modalRef.componentInstance as TModalDialog;
    if (setParameters) {
      setParameters(component);
    }
    return modalRef;
  }

  openSingle<TModalDialog extends BaseModalDialogDirective>(type: new (...args: never[]) => TModalDialog, setParameters?: (d: TModalDialog) => void): NgbModalRef {
    this.openModals.filter(m => m.componentInstance instanceof type).forEach(m => m.close());
    return this.open(type, setParameters);
  }

  isSomeOpen(): boolean {
    return this.ngbModal.hasOpenModals();
  }

  private getModalOptions<TModalDialog extends BaseModalDialogDirective>(type: new (...args: never[]) => TModalDialog, injector?: EnvironmentInjector): NgbModalOptions {
    const closeType = type.prototype.closeType as ModalCloseType;
    const size = type.prototype.size as ModalSize;

    const allowCloseByEscKey = closeType === ModalCloseType.Normal;
    const allowCloseByOutsideClick = closeType === ModalCloseType.Normal;
    const dialogClass = this.getDialogClass(size);

    return {
      backdrop: allowCloseByOutsideClick ? true : 'static',
      keyboard: allowCloseByEscKey,
      centered: true,
      scrollable: true,
      modalDialogClass: dialogClass,
      injector,
    };
  }

  private getDialogClass(size: ModalSize): string {
    switch (size) {
      case ModalSize.Small:
        return 'modal-size-sm';
      case ModalSize.Medium:
        return 'modal-size-md';
      case ModalSize.Large:
        return 'modal-size-lg';
      case ModalSize.XLarge:
        return 'modal-size-xl';
      case ModalSize.XXLarge:
        return 'modal-size-xxl';
      default:
        return 'modal-size-lg';
    }
  }
}
