import { Injectable } from '@angular/core';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { ResourceTaskConfiguration, SchedulerTask, SchedulerTaskConfiguration } from '../../types/scheduler/scheduler-task.type';
import { BaseApiService } from './base-api.service';
import { SharePointDrive } from '../../types/share-point-drive.type';
import { RecoveryConfiguration } from '../../types/scheduler/recovery-configuration.type';

@Injectable({ providedIn: 'root' })
export class TasksApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/tasks`;
  }

  async getAllTasks(): Promise<SchedulerTask[]> {
    const tasks = await this.get<SchedulerTask[]>('');
    tasks.forEach(t => ApiConversionHelper.convertSchedulerTaskConfiguration(t.configuration));
    return tasks;
  }

  async getTasks(processId: string): Promise<SchedulerTask[]> {
    const tasks = await this.get<SchedulerTask[]>(`${processId}`);
    tasks.forEach(t => ApiConversionHelper.convertSchedulerTaskConfiguration(t.configuration));
    return tasks;
  }

  async getRunNowTask(processId: string): Promise<SchedulerTask> {
    const task = await this.get<SchedulerTask>(`${processId}/run-now`);
    ApiConversionHelper.convertSchedulerTaskConfiguration(task?.configuration);
    return task;
  }

  async getResourceTasks(resourceId: string): Promise<SchedulerTask> {
    return await this.get<SchedulerTask>(`resource-task/${resourceId}`);
  }

  async validateTaskCondition(conditionText: string): Promise<string> {
    return await this.postWithResult<{ conditionText: string }, string>('validate-condition', { conditionText });
  }

  async validateTaskAction(actionText: string): Promise<string> {
    return await this.postWithResult<{ actionText: string }, string>('validate-action', { actionText });
  }

  async validateTaskConfiguration(taskConfiguration: SchedulerTaskConfiguration): Promise<boolean> {
    ApiConversionHelper.convertSchedulerTaskConfigurationForApi(taskConfiguration);
    return await this.postWithResult('validate-configuration', { ...taskConfiguration });
  }

  async getSharePointDrive(sharePointDriveUrl: string): Promise<SharePointDrive> {
    return await this.postWithResult<{ sharePointDriveUrl: string }, SharePointDrive>('get-share-point-drive', { sharePointDriveUrl });
  }

  async createTask(taskConfiguration: SchedulerTaskConfiguration): Promise<void> {
    ApiConversionHelper.convertSchedulerTaskConfigurationForApi(taskConfiguration);
    return await this.post<SchedulerTaskConfiguration>('', taskConfiguration);
  }

  async updateTask(taskConfiguration: SchedulerTaskConfiguration): Promise<void> {
    ApiConversionHelper.convertSchedulerTaskConfigurationForApi(taskConfiguration);
    return await this.put('', taskConfiguration);
  }

  async createResourceTask(resourceTaskConfiguration: ResourceTaskConfiguration): Promise<void> {
    return await this.post<ResourceTaskConfiguration>('resource-task', resourceTaskConfiguration);
  }

  async updateResourceTask(resourceTaskConfiguration: ResourceTaskConfiguration): Promise<void> {
    return await this.put('resource-task', resourceTaskConfiguration);
  }

  async createRecoveryTask(recoveryConfiguration: RecoveryConfiguration): Promise<void> {
    return await this.post<RecoveryConfiguration>('recovery', recoveryConfiguration);
  }

  async updateRecoveryTask(recoveryConfiguration: RecoveryConfiguration): Promise<void> {
    return await this.put('recovery', recoveryConfiguration);
  }

  async deleteTask(taskId: string): Promise<void> {
    return await this.delete(`${taskId}`);
  }

  async importTasks(file: File): Promise<string[]> {
    return await this.postWithResult<File, string[]>('import', file);
  }

  downloadTasks(): void {
    this.download('download');
  }

  async setTaskIsActive(taskId: string, isActive: boolean): Promise<SchedulerTask> {
    const task = await this.patch<boolean, SchedulerTask>(`${taskId}/is-active/`, isActive);
    ApiConversionHelper.convertSchedulerTaskConfiguration(task.configuration);
    return task;
  }

  async runTaskNow(taskId: string): Promise<void> {
    return await this.post(`${taskId}/run-now/`, null);
  }

  async cancelTaskNow(taskId: string): Promise<void> {
    return await this.post(`${taskId}/cancel-now/`, null);
  }

  async setSchedulerProcessPriority(processId: string, priority: string): Promise<void> {
    return await this.patch(`processes/${processId}/priority/`, ApiConversionHelper.convertToString(priority));
  }

  async setSchedulerProcessIsActive(processId: string, isActive: boolean): Promise<boolean> {
    return await this.patch(`processes/${processId}/is-active/`, isActive);
  }

  async importTasksFromBluePrismSchedules(): Promise<void> {
    return await this.post('import-from-bp-schedules', null);
  }
}
