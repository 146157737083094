import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DateRange as CalendarDateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { DateHelper } from '../../../helpers/date.helper';
import { DataAvailabilityInfo } from '../../../types/data-availability-info.type';
import { DateRange } from '../../../types/date-range.type';
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';
import { CalendarShortcut } from './calendar-shortcut.type';

@Component({
  selector: 'shared-calendar-body',
  templateUrl: './calendar-body.component.html',
  styleUrls: ['./calendar-body.component.scss'],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarBodyComponent implements OnInit {
  @Input() selectedRange: DateRange;
  @Input() showTodayButton = true;
  @Input() showYesterdayButton = true;
  @Input() show7DaysButton = true;
  @Input() show30DaysButton = true;
  @Input() set dataAvailability(dataAvailability: DataAvailabilityInfo) {
    this._dataAvailability = dataAvailability;
    this.updateDateRanges();
  }

  get dataAvailability(): DataAvailabilityInfo {
    return this._dataAvailability;
  }

  @Input() set activeDate(date: Date) {
    if (this._calendar != null) {
      this._calendar.activeDate = DateTime.fromJSDate(date);
    }
  }

  @Input() showShortcuts = true;
  @Output() selectedRangeChange = new EventEmitter<DateRange>();
  @Output() selectionStateChange = new EventEmitter<boolean>();

  cells!: { date: number; element: HTMLElement; change: boolean; listen?: () => void }[];
  dateOver: any;
  calendarEditingDateRange = false;

  @ViewChild('calendar', { static: false }) set calendar(calendar: MatCalendar<DateTime>) {
    this._calendar = calendar;
    if (calendar != null) {
      calendar.activeDate = DateTime.fromJSDate(this.selectedRange.start);
      calendar.updateTodaysDate();
    }
  }

  get selectedDateRange(): CalendarDateRange<DateTime> {
    return new CalendarDateRange<DateTime>(DateTime.fromJSDate(this.selectedRange.start), DateTime.fromJSDate(this.selectedRange.end));
  }

  get startAt(): DateTime {
    return DateTime.fromJSDate(this.selectedRange.start);
  }

  get minDateTime(): DateTime {
    return this.dataAvailability?.firstDate != null ? DateTime.fromJSDate(this.dataAvailability.firstDate) : null;
  }

  private _calendar: MatCalendar<DateTime>;
  private _dataAvailability: DataAvailabilityInfo;

  readonly customCalendarHeader = CalendarHeaderComponent;
  readonly shortcuts: CalendarShortcut[] = [
    new CalendarShortcut(0, 0, 'Today'),
    new CalendarShortcut(1, 1, 'Yesterday'),
    new CalendarShortcut(7, 1, 'Last 7 days'),
    new CalendarShortcut(30, 1, 'Last 30 days'),
  ];

  get filteredShortcuts(): CalendarShortcut[] {
    return this.shortcuts.filter(
      x =>
        (x.startDayIndex === 0 && this.showTodayButton) ||
        (x.startDayIndex === 1 && this.showYesterdayButton) ||
        (x.startDayIndex === 7 && this.show7DaysButton) ||
        (x.startDayIndex === 30 && this.show30DaysButton)
    );
  }

  dateFilter = (date: DateTime): boolean => {
    return this.dataAvailability?.hasDataForDate(date.toJSDate()) ?? true;
  };

  setClass() {
    return (date: DateTime): string => {
      // is used to reset cells after month change
      if (date.day === 1) {
        this.setCells();
      }
      return '';
    };
  }

  constructor(
    private renderer: Renderer2,
    private readonly selectionModel: MatRangeDateSelectionModel<DateTime>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<DateTime>,
    private cdr: ChangeDetectorRef
  ) {
    this.selectedRange = new DateRange(DateHelper.startOfToday());
  }

  ngOnInit(): void {
    this.updateCalendarAndNotify(false);
  }

  setRangeManually(dateRange: CalendarShortcut): void {
    this.selectedRange = dateRange.getSelectionInterval();
    this.selectedRange = this.dataAvailability.reduceIntervalToAvailableData(this.selectedRange);

    const calendarRange = new CalendarDateRange<DateTime>(DateTime.fromJSDate(this.selectedRange.start), DateTime.fromJSDate(this.selectedRange.end));
    this.selectionModel.updateSelection(calendarRange, this);
    this.updateCalendarAndNotify();
    this.selectionStateChange.emit(false);
  }

  selectedChanged(selectedDate: DateTime): void {
    const selection = this.selectionModel.selection;
    const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);
    this.selectionModel.updateSelection(newSelection, this);
    this.selectedRange = new DateRange(newSelection.start.toJSDate(), newSelection.end?.toJSDate() ?? newSelection.start.toJSDate());

    // start listening for mouseover events on cells if selection is started
    this.calendarEditingDateRange = false;

    if (newSelection.start != null && newSelection.end === null) {
      this.calendarEditingDateRange = true;
      this.redrawCells(null); // reset cells
    } else if (newSelection.start != null && newSelection.end != null) {
      this.updateCalendarAndNotify();
      this.selectionStateChange.emit(false);
      return;
    }

    this.selectionStateChange.emit(true);
  }

  private updateDateRanges(): void {
    this.shortcuts.forEach(x => {
      x.disabled = !this.dataAvailability?.hasAnyDataForInterval(x.getSelectionInterval());
    });
  }

  private updateCalendarAndNotify(notify = true): void {
    this.shortcuts.forEach(x => {
      const selectionInterval = this.dataAvailability.reduceIntervalToAvailableData(x.getSelectionInterval());
      x.selected = this.selectedRange.equals(selectionInterval);
    });

    if (notify) {
      this.selectedRangeChange.emit(this.selectedRange);
    }
    this.cdr.markForCheck();
  }

  private setCells(): void {
    setTimeout(() => {
      if (this.cells) {
        this.cells.forEach(x => {
          x.listen();
        });
      }
      this.dateOver = null;
      const elements = document.querySelectorAll('.calendar');
      if (!elements || elements.length == 0) return;
      const cells = elements[0].querySelectorAll('.mat-calendar-body-cell:not(.mat-calendar-body-disabled');
      this.cells = [];
      cells.forEach((x, index) => {
        const str = x.getAttribute('aria-label');
        const date = new Date(str);
        const time = new Date(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()).getTime();
        this.cells.push({
          date: time,
          element: x as HTMLElement,
          change: time >= this.selectedDateRange.start.valueOf() && time <= this.selectedDateRange.end.valueOf(),
        });
      });
      this.cells.forEach(x => {
        if (!x.listen) {
          x.listen = this.renderer.listen(x.element, 'mouseover', () => {
            if (!this.selectedDateRange.start?.millisecond && this.dateOver != x.date) {
              this.dateOver = x.date;
              this.redrawCells(this.dateOver as number);
            }
          });
        }
      });
    }, 0);
  }

  private redrawCells(timeTo: number): void {
    timeTo = timeTo || this.selectedDateRange.end?.valueOf();
    if (timeTo < this.selectedDateRange.start?.valueOf()) timeTo = this.selectedDateRange.start?.valueOf();
    if (!this.cells) {
      this.setCells();
    }

    this.cells?.forEach(x => {
      const change = this.calendarEditingDateRange ? this.selectedDateRange.start?.valueOf() && x.date >= this.selectedDateRange.start?.valueOf() && x.date <= timeTo : false;
      if (change || x.change) {
        x.change = change;
        const addInside = x.change ? 'addClass' : 'removeClass';
        const addFrom = DateHelper.isSameDay(new Date(x.date), this.selectedDateRange.start.toJSDate())
          ? 'addClass'
          : x.date == timeTo && DateHelper.isSameDay(new Date(timeTo), this.selectedDateRange.start.toJSDate())
            ? 'addClass'
            : 'removeClass';
        const addTo = DateHelper.isSameDay(new Date(x.date), new Date(timeTo))
          ? 'addClass'
          : x.date == this.selectedDateRange.start?.valueOf() && this.selectedDateRange.start?.valueOf() == timeTo
            ? 'addClass'
            : 'removeClass';

        this.renderer[addInside](x.element, 'inside');
        this.renderer[addFrom](x.element, 'from');
        this.renderer[addTo](x.element, 'to');
      }
    });
  }
}
