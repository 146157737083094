import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[sharedScrollToTop]',
})
export class ScrollToTopDirective {
  @Output() scrollToTop = new EventEmitter<void>();

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (window.scrollY === 0) {
      this.scrollToTop.emit();
    }
  }
}
