import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, map, pairwise, startWith } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
import { FilterDefinition, FilterItem, FilterItemType, FilterItemValue } from './filter-definition.type';
import { replace } from '../helpers/array.helper';

export interface FilterDefinitionChangedArgs {
  filter: FilterDefinition;
  changed: string[];
}

@Injectable()
export class FilteringService implements OnDestroy {
  private beforeFilterChangedSubject = new ReplaySubject<FilterDefinition>(1);
  private filterChangedSubject = new ReplaySubject<FilterDefinition>(1);
  private filterDefinition: FilterDefinition;

  constructor(private navigationService: NavigationService) {}

  ngOnDestroy(): void {
    this.filterChangedSubject.complete();
  }

  get beforeFilterChanged$(): Observable<FilterDefinitionChangedArgs> {
    return this.getFilterDefinitionChangedArgs(this.beforeFilterChangedSubject.asObservable());
  }

  get filterChanged$(): Observable<FilterDefinitionChangedArgs> {
    return this.getFilterDefinitionChangedArgs(this.filterChangedSubject.asObservable());
  }

  private getFilterDefinitionChangedArgs(observable: Observable<FilterDefinition>): Observable<FilterDefinitionChangedArgs> {
    return observable.pipe(
      startWith(undefined),
      pairwise(),
      map(([prev, current]) => {
        if (prev == null) {
          return {
            filter: current,
            changed: current.filters.map(f => f.name),
          };
        }

        const changed = current.filters.filter(f => {
          const prevFilter = prev.getFilter(f.name);
          if (prevFilter == null) {
            return false;
          }
          return JSON.stringify(prevFilter.value) !== JSON.stringify(f.value);
        });

        return {
          filter: current,
          changed: changed.map(f => f.name),
        };
      })
    );
  }

  initializeFilters(filters: FilterItem[]): void {
    this.setNewFilters(filters);
  }

  isFilterActive(name: string): boolean {
    return this.filterDefinition.isFilterActive(name);
  }

  async selectFilterValue(name: string, value: string | string[], source: string = null): Promise<void> {
    const filter = this.filterDefinition.getFilter(name);
    if (filter.type !== FilterItemType.Select && filter.type !== FilterItemType.MultiSelect) {
      throw new Error('Filter type is not supported.');
    }

    if (JSON.stringify(filter.value) === JSON.stringify(value)) {
      return;
    }

    const newFilter: FilterItem = filter.type === FilterItemType.Select ? { ...filter, value: value as string, source } : { ...filter, value: value as string[], source };

    await this.updateFilterAndPublish(newFilter);
  }

  async setFilterValue(name: string, value: FilterItemValue, source = ''): Promise<void> {
    const filter = this.filterDefinition.getFilter(name);
    if (filter.type !== FilterItemType.Simple) {
      throw new Error('Filter type is not supported.');
    }

    if (JSON.stringify(filter.value?.id) === JSON.stringify(value?.id)) {
      return;
    }

    const newFilter = {
      ...filter,
      value,
      source,
    };

    await this.updateFilterAndPublish(newFilter);
  }

  async clearFilter(name: string): Promise<void> {
    if (!this.filterDefinition.isFilterActive(name)) {
      return;
    }

    const filter = this.filterDefinition.getFilter(name);
    const newFilter: FilterItem = { ...filter, value: null, source: null };
    await this.updateFilterAndPublish(newFilter);
  }

  async updateFilterItems(name: string, items: FilterItemValue[], selectedValue?: any): Promise<void> {
    const filter = this.filterDefinition.getFilter(name);
    if (filter.type !== FilterItemType.Select && filter.type !== FilterItemType.MultiSelect) {
      throw new Error('Only select type filters are supported.');
    }

    const newFilter: FilterItem = {
      ...filter,
      items: items,
      value: selectedValue,
    };

    await this.updateFilterAndPublish(newFilter);
  }

  private setNewFilters(filters: FilterItem[]): void {
    const newFilterDefinition = new FilterDefinition(filters);
    this.beforeFilterChangedSubject.next(newFilterDefinition);
    this.filterDefinition = newFilterDefinition;
    this.filterChangedSubject.next(this.filterDefinition);
  }

  private async updateFilterAndPublish(newFilter: FilterItem): Promise<void> {
    const filters = [...this.filterDefinition.filters];
    const oldFilter = filters.find(f => f.name === newFilter.name);
    replace(filters, oldFilter, newFilter);
    this.setNewFilters(filters);
    await this.updateQueryParams(filters);
  }

  private async updateQueryParams(filters: FilterItem[]): Promise<void> {
    const queryParams: Record<string, any> = {};
    for (const filter of filters.filter(f => f.queryParamName != null)) {
      if (filter.type === FilterItemType.Simple) {
        queryParams[filter.queryParamName] = filter.value?.id.toString();
      } else if (filter.type === FilterItemType.MultiSelect) {
        queryParams[filter.queryParamName] = filter.value && Array.isArray(filter.value) ? filter.value.join(',') : null;
      } else {
        queryParams[filter.queryParamName] = filter.value;
      }
    }

    await this.navigationService.addQueryParams(queryParams);
  }
}
