<mat-select
  #selectWithSearch
  [(ngModel)]="selectionModel"
  [multiple]="multiple"
  [hasEmptyOption]="hasEmptyOption"
  [placeholder]="placeholder"
  [panelClass]="panelClass"
  [class]="selectClass"
  [class.mat-mdc-select-invalid]="isInvalid"
  [disabled]="disabled"
  (closed)="onClosed(selectWithSearch)"
>
  @if (multiple && getSelectTriggerLabel(); as label) {
    <mat-select-trigger>{{ label }}</mat-select-trigger>
  }

  @for (item of fixedItems; track item.id; let isLast = $last) {
    <mat-option
      #option
      [class.bottom-divider]="isLast"
      [disabled]="item.disabled ?? false"
      [value]="item"
      [tooltip]="item.tooltip"
      [showDelay]="500"
      (click)="onFixedItemClick(item, option.selected)"
      shared-title-if-ellipse
      >{{ item.label }}</mat-option
    >
  }

  @if (items?.length > 5) {
    <mat-option>
      <shared-select-search [(ngModel)]="filter" [searchPlaceholder]="searchPlaceholder"></shared-select-search>
    </mat-option>
  }

  @if (hasEmptyOption === true && items?.length > 0) {
    <mat-option [value]="undefined" [selected]="!selectionModel">{{ emptyOptionLabel }}</mat-option>
  }

  @for (group of groups$ | async; track $index) {
    @if (group.label) {
      <mat-optgroup>
        <b>{{ group.label | uppercase }}</b>
        @for (item of group.items; track item.id) {
          <mat-option [disabled]="item.disabled ?? false" [value]="item" [tooltip]="item.tooltip" [showDelay]="500" (click)="onItemClick()" shared-title-if-ellipse>{{ item.label }}</mat-option>
        }
      </mat-optgroup>
    }

    @if (!group.label) {
      @for (item of group.items; track item.id) {
        <mat-option [disabled]="item.disabled ?? false" [value]="item" [tooltip]="item.tooltip" [showDelay]="500" (click)="onItemClick()" shared-title-if-ellipse>{{ item.label }}</mat-option>
      }
    }
  }
</mat-select>
