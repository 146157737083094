import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, OnDestroy, isDevMode } from '@angular/core';
import { ApplicationInsights, IConfig, IConfiguration, ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web';
import * as StackTrace from 'stacktrace-js';
import { LogLevel } from '../enums/log-level.enum';
import { Global } from '../types/global.type';
import { AuthService } from './auth.service';

declare global {
  interface Window {
    pointee: any;
  }
}

export enum PageNames {
  Processes = 'Processes',
  ProcessDetail = 'ProcessDetail',
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService implements OnDestroy {
  private userId?: string;
  private organizationName?: string;
  private isSystemAdmin?: boolean;
  private appInsights: ApplicationInsights;
  private version: string;
  private deferredTraces: { severity: SeverityLevel; message: string; originalTime: Date; properties: ICustomProperties }[] = [];

  constructor(private injector: Injector) {}

  ngOnDestroy(): void {
    window.pointee = null;
  }

  setupApplicationInsights(connectionString: string, version: string): void {
    this.version = version;
    if (isDevMode() || connectionString == null || connectionString === '') {
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString,
        enableAutoRouteTracking: false,
      },
    });
    this.appInsights.loadAppInsights();

    this.deferredTraces.forEach(t => this.appInsights?.trackTrace({ message: t.message, severityLevel: t.severity }, { ...t.properties, originalTime: t.originalTime }));
    this.deferredTraces = [];

    window.pointee = {
      appInsights: {
        setup: this.setup.bind(this),
      },
    };
  }

  private getAppInsightsProps(): ICustomProperties {
    return {
      UserId: this.userId,
      OrganizationName: this.organizationName,
      Version: this.version,
      IsClient: true,
      IsPointee: this.isSystemAdmin,
      Browser: Global.browserVersion,
      LocaleId: Global.localeId,
    };
  }

  logWindowError(event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error): void {
    const eventMessage = typeof event === 'string' ? event : JSON.stringify(event);
    this.logError(`Window error occurred: ${eventMessage}`, { event, source, lineno, colno, error });
  }

  logException(error: any): void {
    if (error instanceof HttpErrorResponse) {
      // HttpErrorResponse is already logged at the server, so no need to log it again
      return;
    }

    const typedError: Error = error instanceof Error ? error : new Error(String(error));
    void StackTrace.fromError(typedError, { offline: true }).then(stackframes => {
      const stack = stackframes
        .splice(0, 20)
        .map(sf => `    at ${sf}`)
        .join('\n');
      let message = typedError.message;
      if (message.includes('\n')) {
        message = message.substring(0, message.indexOf('\n'));
      }

      this.appInsights?.trackException(
        {
          error: { name: 'Error', message, stack },
          severityLevel: SeverityLevel.Error,
        },
        this.getAppInsightsProps()
      );

      this.writeToLog(LogLevel.Error, `Exception occurred: ${message}`, { stack }, typedError);
    });
  }

  async trackEvent(pageName: PageNames, operationName: string, method: () => Promise<any>): Promise<any> {
    const start = Date.now();
    await method();
    const duration = Date.now() - start;
    const props = this.getAppInsightsProps();
    this.appInsights?.trackEvent(
      {
        name: pageName,
        properties: {
          url: location.pathname,
          pageName,
          operation: operationName,
          timeMs: duration,
        },
      },
      props
    );
  }

  trackTrace(logLevel: LogLevel, message: string, properties?: Record<string, any>): void {
    const fullProperties = { ...properties, ...this.getAppInsightsProps() };

    let severity: SeverityLevel = SeverityLevel.Verbose;
    switch (logLevel) {
      case LogLevel.Error:
        severity = SeverityLevel.Error;
        break;
      case LogLevel.Fatal:
        severity = SeverityLevel.Critical;
        break;
      case LogLevel.Warning:
        severity = SeverityLevel.Warning;
        break;
      case LogLevel.Information:
        severity = SeverityLevel.Information;
        break;
      default:
        severity = SeverityLevel.Verbose;
    }

    if (this.appInsights == null) {
      this.deferredTraces.push({ severity, message, properties, originalTime: new Date() });
      return;
    }

    this.appInsights?.trackTrace({ message, severityLevel: severity }, fullProperties);
  }

  logPageView(pageName: string, url: string, properties: Record<string, string>, duration: number): void {
    const trackProperties = {
      duration: duration,
      ...this.getAppInsightsProps(),
      ...properties,
    };
    this.appInsights?.trackPageView({
      name: pageName,
      uri: url,
      properties: trackProperties,
    });
    this.logInformation(`Page changed to ${pageName} `);
  }

  logTrace(message: string, properties?: Record<string, any>): void {
    this.writeToLog(LogLevel.Trace, message, properties);
  }

  logDebug(message: string, properties?: Record<string, any>): void {
    this.writeToLog(LogLevel.Debug, message, properties);
  }

  logInformation(message: string, properties?: Record<string, any>): void {
    this.writeToLog(LogLevel.Information, message, properties);
  }

  logWarning(message: string, properties?: Record<string, any>): void {
    this.writeToLog(LogLevel.Warning, message, properties);
  }

  logError(message: string, properties?: Record<string, any>): void {
    this.writeToLog(LogLevel.Error, message, properties);
  }

  logFatal(message: string, properties?: Record<string, any>): void {
    this.writeToLog(LogLevel.Fatal, message, properties);
  }

  private writeToLog(logLevel: LogLevel, message: string, properties?: Record<string, any>, consoleError?: Error): void {
    if (!isDevMode() && logLevel < LogLevel.Information) {
      return;
    }
    // log to console till 'DEBUG' level
    if (isDevMode() || this.appInsights == null) {
      if (consoleError != null) {
        console.log(consoleError);
      } else if (properties != null) {
        console.log(message, properties);
      } else {
        console.log(message);
      }
    }

    this.trackTrace(logLevel, message, properties);
  }

  setAuthenticatedUser(userId: string, organizationName: string, isSystemAdmin: boolean): void {
    this.userId = userId;
    this.organizationName = organizationName;
    this.isSystemAdmin = isSystemAdmin;
  }

  clearAuthenticatedUser(): void {
    this.userId = null;
    this.organizationName = null;
    this.isSystemAdmin = null;
  }

  private setup(config: IConfiguration & IConfig): string {
    if (!this.injector.get(AuthService).isSystemAdmin) {
      return null;
    }

    if (config == null) {
      return 'Invalid configuration';
    }

    void this.appInsights?.unload(false);

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: this.appInsights.config.connectionString,
        enableAutoRouteTracking: false,
        ...config,
      },
    });

    this.appInsights.loadAppInsights();

    console.log('config', this.appInsights.config);
    return 'AppInsights configuration changed';
  }
}
